import React, { useState, useCallback, lazy, Suspense } from "react"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import ClipLoader from "react-spinners/ClipLoader"

//constant imports
import RoutesFunction from "../themes/routes"

//navigator imports
const AppNavigator = lazy(
  () => import("../features/App/navigation/AppNavigation")
)
const AuthNavigator = lazy(
  () => import("../features/Authentication/navigation/AuthNavigation")
)
const ExternalNavigation = lazy(
  () => import("../features/App/navigation/ExternalNavigation")
)
//Calendar
const CalendarPicker = lazy(
  () => import("../features/Calendar/CalendarPicker/CalendarPicker")
)
const Calendly = lazy(() => import("../features/Calendly/Calendly"))

const MainNavigator = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const routes = RoutesFunction()

  const toggleUserLogged = useCallback((value: boolean) => {
    setLoggedIn(value)
  }, [])

  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to={routes.app.route} />
        <Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <ClipLoader color={"grey"} size={40} />
            </div>
          }
        >
          <Route path={routes.app.route}>
            <AppNavigator
              loggedIn={loggedIn}
              toggleUserLogged={toggleUserLogged}
            />
          </Route>
          <Route path={routes.auth.route}>
            <AuthNavigator
              loggedIn={loggedIn}
              toggleUserLogged={toggleUserLogged}
            />
          </Route>
          <Route path={routes.calendar.picker}>
            <CalendarPicker />
          </Route>
          <Route path={routes.external.route}>
            <ExternalNavigation />
          </Route>
          <Route path={routes.calendly.route}>
            <Calendly />
          </Route>
        </Suspense>
      </Switch>
    </BrowserRouter>
  )
}

export default MainNavigator
