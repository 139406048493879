import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translations_en from "./translations/en/translations.json"
import translations_fr from "./translations/fr/translations.json"
import routes_en from "./translations/en/routes.json"
import routes_fr from "./translations/fr/routes.json"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: true,
    lng: "en",
    ns: ["translations", "routes"],
    resources: {
      en: {
        translations: translations_en,
        routes: routes_en,
      },
      fr: {
        translations: translations_fr,
        routes: routes_fr,
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
