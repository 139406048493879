import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import reportWebVitals from "./reportWebVitals"
import Amplify from "aws-amplify"
import AWS from "aws-sdk"
import config from "./aws-exports"
import MainNavigator from "./mainNavigator/MainNavigator"
import "./i18n"

import markerSDK from "@marker.io/browser"
import awsmobile from "./aws-exports"

const widget = markerSDK.loadWidget({
  destination: "629cd138518e98007da74262",
})

AWS.config.update({
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: awsmobile.aws_cognito_identity_pool_id,
  }),
  region: awsmobile.aws_project_region,
})

Amplify.configure({
  ...config,
  aws_cloud_logic_custom: [
    ...config.aws_cloud_logic_custom,
    {
      name: "local",
      endpoint: "http://localhost:3000",
      region: "us-east-2",
    },
    {
      name: "nlp",
      endpoint: "http://localhost:3001",
      region: "us-east-2",
    },
    {
      name: "calendar",
      endpoint: "http://localhost:3003",
      region: "us-east-2",
    },
  ],
})
ReactDOM.render(
  <React.StrictMode>
    {/* <I18nextProvider i18n={i18next}> */}
    {/* <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}> */}
    <Suspense fallback="loading">
      <MainNavigator />
    </Suspense>
    {/* </PersistGate>
    </Provider>
  </I18nextProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
