import { useTranslation } from "react-i18next"

const RoutesFunction = () => {
  const { t } = useTranslation("routes")
  const routes = {
    calendly: {
      route: "/calendly/:url",
      name: t("calendly.name"),
    },
    calendar: {
      picker: "/calendar/picker/:interviewId/:interviewerId/:intervieweeEmail",
      name: t("calendar.name"),
    },
    futures: {
      route: "/app/futures/",
      name: "Futures",
    },
    search: {
      route: "/app/search/:keyword",
      name: t("search.name"),
    },
    dashboard: {
      route: "/app/dashboard",
      name: t("dashboard.name"),
    },
    projects: {
      route: "/app/projects",
      name: t("projects.name"),
    },
    topicListBuilder: {
      route: "/app/projects/topic-list-builder",
      name: t("topicListBuilder.name"),
    },
    projectDetails: {
      route: "/app/projects/project-details",
      name: t("projectDetails.name"),
    },
    chart: {
      route: "/app/chart",
      name: t("chart.name"),
    },
    methodology: {
      route: "/app/methodology",
      name: t("methodology.name"),
    },
    surveys: {
      route: "/app/surveys/",
      videoRoute: "/app/surveys/:surveyId?",
      name: t("surveys.name"),
    },
    projectWizzard: {
      route: "/app/projectWizard",
      name: t("projectWizzard.name"),
    },
    interviewWizzard: {
      route: "/app/interviewWizard",
      name: t("interviewWizzard.name"),
    },
    interview: {
      route: "/app/interviews/",
      videoRoute: "/app/interviews/:interviewId?",
      name: t("interview.name"),
    },
    interviewList: {
      route: "/app/interview-list",
      name: t("interviewList.name"),
    },
    interviewSession: {
      route: "/app/interviewSession/:id/:email",
      name: t("interviewSession.name"),
    },
    workshops: {
      route: "/app/workshops",
      name: t("workshops.name"),
    },
    matrix: {
      route: "/app/matrix",
      name: t("matrix.name"),
    },
    test: {
      route: "/app/test",
      name: t("test.name"),
    },
    root: {
      route: "/",
      name: t("root.name"),
    },
    app: {
      route: "/app",
      name: t("app.name"),
    },
    auth: {
      route: "/auth",
      name: t("auth.name"),
    },
    login: {
      route: "/login",
      name: t("login.name"),
    },
    register: {
      route: "/register",
      name: t("register.name"),
    },
    admin: {
      route: "/app/admin",
      name: t("admin.name"),
      issues: {
        route: "/app/admin/topics",
        name: t("admin.issues.name"),
      },
      scoring: {
        route: "/app/admin/scoring",
        name: t("admin.scoring.name"),
      },
      sectors: {
        route: "/app/admin/sectors",
        name: t("admin.sectors.name"),
      },
      companies: {
        route: "/app/admin/companies",
        name: t("admin.companies.name"),
      },
      people: {
        route: "/app/admin/people",
        name: t("admin.people.name"),
      },
      regions: {
        route: "/app/admin/regions",
        name: t("admin.regions.name"),
      },
      auditLogs: {
        route: "/app/admin/audit-logs",
        name: t("admin.auditLogs.name"),
      },
      profile: {
        route: "/app/admin/my-profile",
        name: t("admin.profile.name"),
      },
    },
    methodologyTemplate: {
      route: "/app/methodology-template",
      name: t("methodologyTemplate.name"),
    },
    forgotPass: {
      route: "/forgot-password",
      name: t("forgotPass.name"),
    },
    insights: {
      route: "/app/projects/insights/",
      name: t("insights.name"),
    },
    external: {
      route: "/external",
      name: t("external.name"),
    },
    externalMatrix: {
      route: "/external/matrix",
      name: t("externalMatrix.name"),
    },
    externalTopicList: {
      route: "/external/topic-list",
      name: t("externalTopicList.name"),
    },
    externalScores: {
      route: "/external/scores",
      name: t("externalScores.name"),
    },
    criteria: {
      route: "/app/projects/criteria",
      name: t("criteria.name"),
    },
  }
  return routes
}
export default RoutesFunction
